import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import DataLoadSpinner from "../../components/Reusable/Spinner";
const ForgetPasswordComponent: any = Loadable({
  loader: () => import("../../components/Account/ForgetPassword"),
  loading: DataLoadSpinner,
});
const ForgetPassword = () => {
  const navigate = useNavigate();

  const handleOTP = async (data: any) => {
    localStorage.setItem("email", JSON.stringify(data));
    try {
      const response = await fetch(
        "https://usermanagement.dibsolutions.com.au/api/user/send-otp/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Please try again later");
      }
      toast.success("OTP sent successfully");
      navigate("/auth-login/reset-password");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <ForgetPasswordComponent handleOTP={handleOTP} />
    </>
  );
};

export default ForgetPassword;
