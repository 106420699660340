import { Outlet } from "react-router-dom";
import DibtechLogo from "../assets/dibtech-logo.png";
import { tw } from "twind";

const AuthLayout = () => {
  return (
    <div
      className={tw`h-[100vh] bg-[#eef1f8] flex flex-col justify-center sm:px-6 lg:px-8`}
    >
      <div className={tw`mt-8 sm:mx-auto sm:w-full sm:max-w-md sm: px-5`}>
        <div className={tw`flex items-center justify-center pb-10`}>
          <img src={DibtechLogo} alt="dibtech-logo" className={tw`w-40`} />
        </div>
        <div
          className={tw`bg-white py-4 lg:px-12 md:px-12 px-10 shadow-lg lg:rounded-2xl md:rounded-2xl rounded-lg`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
