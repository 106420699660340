import { useNavigate } from "react-router-dom";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { setLocalKey } from "../../helpers/sessionKey";
import DataLoadSpinner from "../../components/Reusable/Spinner";

const LoginComponent: any = Loadable({
  loader: () => import("../../components/Account/Login"),
  loading: DataLoadSpinner,
});
const Login = () => {
  const navigate = useNavigate();
  const handleLogin = async (data: any) => {
    try {
      const response = await fetch(
        "https://usermanagement.dibsolutions.com.au/api/token/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (response.status === 401) {
        toast.error(result?.detail ?? "Invalid username or password");
      } else if (response.status === 200) {
        toast.success("Login successfully");
        setLocalKey("token", result?.access);
        navigate("/", { replace: true });
      }
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  return (
    <>
      <LoginComponent handleLogin={handleLogin} />
    </>
  );
};

export default Login;
